/** @format */

import { Box, Container, Typography } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
export default function TestimonialCard({ testimonial }: any) {
  return (
    <Box
      sx={{
        width: { xs: "70%", sm: "80%", md: 532 }, // Ensuring the card does not exceed this width
        margin: "auto", // Center the card in the slide
        height: { xs: 310, md: 274 }, // Fixed height
        padding: "40px",
        border: "1px solid #ddd",
        borderRadius: "20px",
        boxShadow: "8px 8px 20px 0px #0000001A", // Subtle shadow for a soft floating effect
        background: "white",
        textAlign: "center",
        transition: "box-shadow 0.3s ease-in-out",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 400,
            fontFamily: "MyOwnFont,PublicSans",
            color: " rgba(39, 49, 80, 1)",
            textAlign: "justify",
          }}
        >
          {testimonial.text}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: 4,
          }}
        >
          <img
            src={require(`../assets/avatars/${testimonial.image}.png`)}
            style={{ width: 44 }}
          ></img>
          <Typography
            sx={{
              marginLeft: 1,
              fontSize: 18,
              fontWeight: 600,
              fontFamily: "MyOwnFont,PublicSans",
              color: "  #1D2538 ",
              textAlign: "justify",
            }}
          >
            {testimonial.name}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
