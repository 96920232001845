/** @format */

import { Typography, Box } from "@mui/material";
import "../business.css";

import React from "react";
import TopBar from "../components/Navbar";

const ContactUs = ({ navigation }: any) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 850);
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const topTextStyle = isMobile
    ? {
        marginTop: 10,
        fontWeight: 600,
        fontSize: 34,
        textAlign: "center",
        fontFamily: "MyHeboFont,Heebo-Bold",
        marginX: 2.5,
      }
    : {
        marginTop: 5,
        fontWeight: 600,
        fontSize: 54,
        textAlign: "center",
        fontFamily: "MyHeboFont,Heebo-Bold",
        marginX: 2.5,
      };
  const descriptionTextStyle = isMobile
    ? {
        fontWeight: 600,
        fontSize: 20, // Default font size
        textAlign: "center",
        fontFamily: "MyCustomFont,CalSans",
      }
    : {
        fontWeight: 600,
        fontSize: 36, // Default font size
        textAlign: "center",
        fontFamily: "MyCustomFont,CalSans",
      };
  return (
    <>
      <TopBar navigation={navigation} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={topTextStyle}>Contact Us</Typography>
        <Typography sx={topTextStyle}>Reshape Technologies Inc</Typography>
        <Typography sx={descriptionTextStyle}>15 Parish Ave,</Typography>
        <Typography sx={descriptionTextStyle}>Ajax, ON - L1Z 1N2</Typography>
        <Typography sx={descriptionTextStyle}>
          email:{" "}
          <a href="mailto:support@reshapeapp.ai">support@reshapeapp.ai</a>
        </Typography>
        <Box
          sx={{
            position: "fixed", // Add this line to fix the position
            bottom: 0, // Add this line to align it at the bottom
            width: "100%",
            height: 33,
            backgroundColor: "#1067bf",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
          }}
        >
          <Typography>
            © All rights reserved | Reshape Technologies Inc
          </Typography>
        </Box>
      </div>
    </>
  );
};

export default ContactUs;
