/** @format */

import { Typography, Box, Grid, Button, CircularProgress } from "@mui/material";
import "./business.css";

import TopBar from "./components/Navbar";
import React, { Suspense, useEffect, useState } from "react";
import {} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

import Testimonials from "./components/Testimonials";
import BigscreenBottomBar from "./components/BigscreenBottomBar";
import MobileScreenBottomBar from "./components/MobilescreenBottomBar";
const backgroundImage = require("../src/assets/images/backgroundimage.png");
// Lazy load the Lottie component
const Lottie = React.lazy(() => import("react-lottie-player"));
const Home = ({ navigation }: any) => {
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 10000); // 10 seconds delay

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, []);
  const handleImageLoad = () => {
    setLoading(false);
  };
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 850);
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const buttonStyle = isMobile
    ? { width: "100%", cursor: "pointer", marginTop: 15 }
    : { width: 242, cursor: "pointer", marginTop: 15 };

  return (
    <>
      <TopBar navigation={navigation} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "100%" },
            maxWidth: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: { xs: 2, sm: 6, md: 6 },
          }}
        >
          {/* Top section */}
          <Grid container spacing={4} alignItems="center">
            <Grid
              item
              xs={12}
              md={7}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: 328, md: "85%" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginLeft: { xs: 0, md: 12 },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: 32, md: 48 }, // 24px on xs, 32px on md and up
                    textAlign: "start",
                    fontFamily: "MyOwnFont,PublicSans",
                  }}
                >
                  Simplify your health journey{" "}
                  <span
                    style={{
                      fontWeight: 500,

                      textAlign: "start",
                      fontFamily: "MyOwnFont,PublicSans",
                    }}
                  >
                    with Reshape
                  </span>
                </Typography>
                {/* 
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: 20, md: 28 }, // 24px on xs, 32px on md and up
                    textAlign: "start",
                    fontFamily: "MyOwnFont,PublicSans",
                  }}
                >
                  Powered by your{" "}
                  <span
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, rgba(241, 112, 141, 1), rgba(171, 79, 244, 1))",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      display: "inline",
                      fontWeight: 700,
                    }}
                  >
                    personal AI fitness coach, Fio!
                  </span>
                </Typography> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Button
                    href="https://play.google.com/store/apps/details?id=com.nomatictech.nutribook"
                    className="btn"
                    target="_blank_"
                    sx={{
                      textDecoration: "NONE",
                      color: "white",
                      width: { xs: "48%", md: 260, sm: "45%" },
                      marginTop: 2,
                      borderRadius: 7,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={require("../src/assets/images/google_play.png")}
                      style={{ width: "100%" }}
                    />
                  </Button>

                  <Button
                    href="https://apps.apple.com/in/app/reshape-ai/id1630709539"
                    className="btn"
                    target="_blank_"
                    sx={{
                      textDecoration: "NONE",
                      color: "white",
                      width: { xs: "48%", md: 260, sm: "45%" },
                      marginTop: 2,
                      marginLeft: 3,
                      borderRadius: 7,
                      overflow: "hidden",
                    }}
                  >
                    {" "}
                    <img
                      src={require("../src/assets/images/app_store.png")}
                      style={{ width: "100%" }}
                    />
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 2, // Adjust the margin as needed
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: { xs: 15, md: 16 },
                      textAlign: "start",
                      fontFamily: "MyOwnFont,PublicSans",
                      color: "rgba(88, 87, 87, 1)",
                    }}
                  >
                    Trusted by 45k+ happy users
                  </Typography>

                  <Box
                    sx={{
                      color: "#DFB300",
                      marginLeft: 1,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {[...Array(5)].map((_, index) => (
                      <StarIcon key={index} sx={{ fontSize: { xs: 23 } }} />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={5}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `url(${backgroundImage})`, // Set background image
                backgroundSize: "cover", // Cover the entire container
                backgroundPosition: "center", // Center the background image
                position: "relative", // Set container to relative positioning
              }}
            >
              <Box sx={{ width: { xs: "100%", md: 480 } }}>
                <Suspense
                  fallback={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Ensures the loader is centered

                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                >
                  <Lottie
                    loop
                    animationData={require("../src/assets/jsonimage/Reshape.json")} // Adjust the path to where your JSON file is located
                    play
                    style={{ width: "100%" }} // You can adjust the size as needed
                  />
                </Suspense>
              </Box>
            </Grid>
          </Grid>

          {/* Section 1 */}
          <Grid
            container
            alignItems="center"
            sx={{
              marginTop: { xs: 6, md: 10 },
              width: { xs: 328, md: "100%" },
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                order: { xs: 2, md: 1 },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: 24, md: 32 }, // 24px on xs, 32px on md and up
                  textAlign: "justify",
                  color: "rgba(28, 28, 28, 1) ",

                  fontFamily: "MyOwnFont,PublicSans",
                  width: { xs: "100%", md: 446 }, // 100% width on xs, 446px on md and up
                }}
              >
                One-click meal logging with image & voice
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: 16, md: 16 }, // 14px on xs, 16px on md and up
                  textAlign: "justify",
                  fontFamily: "MyOwnFont,PublicSans",
                  width: { xs: "100%", md: 446 }, // 100% width on xs, 446px on md and up
                  marginTop: 2,
                  color: " #475467",
                }}
              >
                Log your meals effortlessly with Reshape's intuitive interface.
                Snap a photo of your dish or use voice input to describe your
                meal, and let Reshape's AI technology identify and record the
                details for you.
              </Typography>
              {/* Blue vertical line */}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                order: { xs: 1, md: 2 },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: 601 },
                }}
              >
                <Suspense
                  fallback={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Ensures the loader is centered
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                >
                  <Lottie
                    loop
                    animationData={require("../src/assets/jsonimage/image1json.json")} // Adjust the path to where your JSON file is located
                    play
                    style={{ width: "100%" }} // You can adjust the size as needed
                  />
                </Suspense>
              </Box>
            </Grid>
          </Grid>

          {/* Section 2 */}
          <Grid
            container
            alignItems="center"
            sx={{
              marginTop: { xs: 4, md: 10 },
              width: { xs: 328, md: "100%" },
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: 601 },
                }}
              >
                <Suspense
                  fallback={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Ensures the loader is centered
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                >
                  <Lottie
                    loop
                    animationData={require("../src/assets/jsonimage/fiojson.json")} // Adjust the path to where your JSON file is located
                    play
                    style={{ width: "100%" }} // You can adjust the size as needed
                  />
                </Suspense>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                order: { xs: 2, md: 1 },
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: 24, sm: 32 }, // Smaller font size on xs, default on sm and up
                  textAlign: "justify",
                  color: " rgba(28, 28, 28, 1) ",
                  fontFamily: "MyOwnFont,PublicSans",
                  width: { xs: "100%", sm: 446 }, // Full width on xs, default width on sm and up
                }}
              >
                Meet Fio, your personal fitness coach powered by AI
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  marginLeft: 1,
                  marginRight: 1,

                  width: { xs: "100%", sm: 446 },
                }}
                mt={2}
              >
                <Typography>•</Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: 16, sm: 16 },
                    textAlign: "start",
                    fontFamily: "MyOwnFont,PublicSans",
                    color: "rgba(71, 84, 103, 1)",

                    marginLeft: 1,
                  }}
                >
                  Fio can help you to log meals
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginLeft: 1,
                  marginRight: 1,
                  width: { xs: "100%", sm: 446 }, // Full width on xs, default width on sm and up
                }}
              >
                <Typography>•</Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: 16, sm: 16 }, // Smaller font size on xs, default on sm and up
                    textAlign: "justify",
                    fontFamily: "MyOwnFont,PublicSans",
                    color: "rgba(71, 84, 103, 1)",

                    marginLeft: 1,
                  }}
                >
                  Fio can suggest recipes and workout plans based on your
                  preferences
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  marginLeft: 1,
                  marginRight: 1,
                  width: { xs: "100%", sm: 446 },
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography>•</Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: { xs: 16, sm: 16 }, // Smaller font size on xs, default on sm and up
                      textAlign: "justify",
                      fontFamily: "MyOwnFont,PublicSans",
                      color: "rgba(71, 84, 103, 1)",

                      marginLeft: 1,
                    }}
                  >
                    Fio is the personal coach that you need to help you with
                    your fitness journey
                  </Typography>
                </Box>
                {/* <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: { xs: "center", md: "flex-start" },
                  }}
                >
                  <img
                    src={require("../src/assets/images/button.png")}
                    style={buttonStyle}
                  />
                </Box> */}
              </Box>
            </Grid>
          </Grid>

          {/* Section 3 */}
          <Grid
            container
            alignItems="center"
            sx={{
              marginTop: { xs: 6, md: 10 },
              width: { xs: 328, md: "100%" },
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                order: { xs: 2, md: 1 },
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: 24, md: 32 }, // Smaller font size on xs, default on sm and up
                  textAlign: "justify",
                  color: " rgba(28, 28, 28, 1) ",
                  fontFamily: "MyOwnFont,PublicSans",
                  width: { xs: "100%", sm: 446 }, // Full width on xs, default width on sm and up
                }}
              >
                Sleep, steps & sweat - Get a personalized workout plan based on
                your activity!
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginLeft: 1,
                  marginRight: 1,
                }}
                mt={2}
              >
                <Typography>•</Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: 16, sm: 16 },
                    textAlign: "justify",
                    fontFamily: "MyOwnFont,PublicSans",
                    color: "rgba(71, 84, 103, 1)",
                    width: { xs: "100%", sm: 446 },
                    marginLeft: 1,
                  }}
                >
                  Import all your fitness metrics in one place - Seamlessly
                  connect with your health apps and make your AI coach aware of
                  all your data for personalized guidance.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginLeft: 1,
                  marginRight: 1,
                }}
              >
                <Typography>•</Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: 16, sm: 16 }, // Smaller font size on xs, default on sm and up
                    textAlign: "justify",
                    fontFamily: "MyOwnFont,PublicSans",
                    color: "rgba(71, 84, 103, 1)",

                    width: { xs: "100%", sm: 446 }, // Full width on xs, default width on sm and up

                    marginLeft: 1,
                  }}
                >
                  Generate personalized strength training plans - Generate
                  workout plans based on your schedule, preferences and where
                  you want to workout. Our hyper-intelligent AI has you covered!
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                order: { xs: 1, md: 2 },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: 400 },
                }}
              >
                <Suspense
                  fallback={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Ensures the loader is centered
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                >
                  <Lottie
                    loop
                    animationData={require("../src/assets/jsonimage/workout.json")} // Adjust the path to where your JSON file is located
                    play
                    style={{ width: "100%" }} // You can adjust the size as needed
                  />
                </Suspense>
              </Box>
            </Grid>
          </Grid>

          {/* Section 4 */}
          <Grid
            container
            alignItems="center"
            sx={{
              marginTop: { xs: 6, md: 10 },
              width: { xs: 328, md: "100%" },
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: 484, sm: 300 },
                }}
              >
                <Suspense
                  fallback={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Ensures the loader is centered
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                >
                  <Lottie
                    loop
                    animationData={require("../src/assets/jsonimage/calories.json")} // Adjust the path to where your JSON file is located
                    play
                    style={{ width: "100%" }} // You can adjust the size as needed
                  />
                </Suspense>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: 24, sm: 32 }, // Adjusts font size for xs screens, with default for sm and above
                  textAlign: "justify",
                  color: "rgba(28, 28, 28, 1)",

                  fontFamily: "MyOwnFont,PublicSans",
                  width: { xs: "100%", sm: 446 }, // Full width for xs screens, specified width for sm and above
                }}
              >
                Data at your fingertips
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: 16, sm: 16 },
                  textAlign: "justify",
                  color: "rgba(71, 84, 103, 1)",

                  fontFamily: "MyOwnFont,PublicSans",
                  width: { xs: "100%", sm: 446 },
                  marginTop: 2,
                }}
              >
                Data representation for your nutrition intake with detailed
                charts. Track your progress over time, monitor changes in
                calorie consumption, macronutrient distribution, and more. Stay
                motivated and informed on your journey towards better nutrition.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>
      {/* Image section-----------------------------------------------------------------------/ */}

      <Grid container spacing={4} alignItems="center" my={6}>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={require(isMobile
              ? "../src/assets/images/image6.png"
              : "../src/assets/images/image5.png")}
            alt="Section 1"
            style={{
              width: "100%",
              display: loading ? "none" : "block",
            }}
            onLoad={handleImageLoad}
          />
        </Grid>
      </Grid>
      <Testimonials />

      {/* Section 1 */}
      <Grid
        container
        spacing={4}
        alignItems="center"
        sx={{ marginTop: { xs: 6, md: 12 } }}
        id="downloadSection"
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: 730 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 32, sm: 48 },
                fontWeight: 700,
                textAlign: "center",
                color: " #1C1C1C",
                fontFamily: "MyOwnFont,PublicSans",
              }}
            >
              Ready to Reshape Your Health and Fitness Journey?
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 20, sm: 20 },
                fontWeight: 400,
                textAlign: "center",
                marginTop: 2,
                color: " #585757",
                fontFamily: "MyInterFont,Inter",
              }}
            >
              Join thousands of others who are transforming their lives with
              Reshape today. Take control of your nutrition, fitness, and
              well-being with AI
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Button
                href="https://play.google.com/store/apps/details?id=com.nomatictech.nutribook"
                className="btn"
                target="_blank_"
                sx={{
                  textDecoration: "NONE",
                  color: "white",
                  width: { xs: "48%", md: 260 },
                  marginTop: 5,
                  borderRadius: 7,
                  overflow: "hidden",
                }}
              >
                <img
                  src={require("../src/assets/images/google_play.png")}
                  style={{ width: "100%" }}
                />
              </Button>

              <Button
                href="https://apps.apple.com/in/app/reshape-ai/id1630709539"
                className="btn"
                target="_blank_"
                sx={{
                  textDecoration: "NONE",
                  color: "white",
                  width: { xs: "48%", md: 260 },
                  marginTop: 5,
                  borderRadius: 7,
                  overflow: "hidden",
                }}
              >
                {" "}
                <img
                  src={require("../src/assets/images/app_store.png")}
                  style={{ width: "100%" }}
                />
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <BigscreenBottomBar />
      <MobileScreenBottomBar />
    </>
  );
};

export default Home;
