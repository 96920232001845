/** @format */

import React from "react";
import {
  AppBar,
  Toolbar,
  Hidden,
  Button,
  IconButton,
  Typography,
  Grid,
  useTheme,
} from "@mui/material";
import "../Home.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const imagePath = require("../assets/images/logo.png");
interface TopBar {
  homeActive?: any;
  navigation?: any;
  toggleBottom?: any;
  setToggleBottom?: any;
}
export default function TopBar({
  homeActive,
  navigation,
  toggleBottom,
  setToggleBottom,
}: TopBar) {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const renderMobileMenu = () => {
  //   return (
  //     <Button
  //       sx={{
  //         backgroundColor: "#1067bf",
  //         color: "white",
  //         borderRadius: 23,
  //         borderWidth: 1,
  //         borderColor: "#000000",
  //       }}
  //       component={Link}
  //       to="/download"
  //     >
  //       Download
  //     </Button>
  //   );
  // };
  function functionCall(navigation: any) {
    if (homeActive) {
      console.log("active");
      if (toggleBottom === false) {
        setToggleBottom(true);
      }
    } else {
      navigation.navigate("/download", { redirecting: "yes" });
    }
  }
  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor: "#ffffff",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ width: { xs: "100%", md: "95%" } }}
        >
          <Grid item>
            <Button
              sx={{ width: { xs: 180, md: 280 }, marginLeft: { xs: 0, md: 1 } }}
              component={Link}
              to="/"
            >
              <img src={imagePath} alt="Logo" width={"100%"} />
            </Button>
          </Grid>
          <Grid item sx={{ display: { md: "block" } }}>
            {location.pathname === "/" && ( // Render the button only on the home page
              <Button
                variant="contained"
                sx={{
                  borderRadius: { xs: 2, md: 2 },
                  width: { xs: 80, md: 120 },
                  height: "auto",
                  fontSize: { xs: 11, md: 16 },
                  textTransform: "none",
                  fontFamily: "MyCustomFont,PublicSans",
                  backgroundColor: "#0066FF",
                  padding: "8px 16px",
                }}
                onClick={() => {
                  const downloadSection =
                    document.getElementById("downloadSection");
                  if (downloadSection) {
                    console.log("Download section found!");
                    downloadSection.scrollIntoView({ behavior: "smooth" });
                  } else {
                    console.log("Download section not found!");
                  }
                }}
              >
                Get App
              </Button>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </div>
  );
}
