import { Container, Typography, Divider } from "@mui/material";
import TopBar from "./components/Navbar";
const PrivacyPolicy = ({ navigation }: any) => {
  return (
    <>
      <TopBar navigation={navigation} />
      <Container sx={{ marginBlock: 10 }}>
        <Typography variant="h4" gutterBottom>
          Reshape Privacy Policy and Terms of Service
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last Updated: 26 January 2024
        </Typography>

        <Divider sx={{ my: 2 }} />
        <Typography variant="body1" paragraph>
          Reshape Technologies Inc. ("we," "us," or "our") respects the privacy
          of our users ("user" or "you"). This privacy policy covers how we
          collect, use, disclose, and safeguard information about you when you
          visit the App (“Reshape – Track meals with AI” (in Google Play Store)
          and “Reshape AI” (in Apple App Store)), as well as any other media
          format, media channel, mobile website, or mobile application
          associated with or connected to the App. Please take the time to read
          our privacy policy carefully. If you do not agree to the terms of this
          privacy policy, please do not access the App.
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to amend this Privacy policy at any time and for
          any reason. Any changes to our Privacy Policy will be communicated to
          you by updating the "Last Updated" date at the top of this page. Any
          changes or modifications will take effect immediately upon the updated
          Privacy Policy's posting on the App, and you renounce your right to
          receive explicit notice of any such change or modification.
        </Typography>
        <Typography variant="body1" paragraph>
          It is recommended that you review our Privacy policy on a frequent
          basis to ensure you are informed of any changes. By continuing to use
          the Site after the updated Privacy Policy is posted, you are deemed to
          have been made aware of, bound by, and accepting the new Privacy
          Policy's modifications.
        </Typography>
        <Typography variant="h6" gutterBottom>
          1. Data We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          When you use Reshape, we may collect the following types of personal
          information:
        </Typography>
        <ul>
          <li>Basic personal details such as name and age.</li>
          <li>Physical attributes, including height and weight.</li>
          <li>
            Daily activities like sleep, food intake, water consumption, and
            step count.
          </li>
          <li>
            Any other data that can be collected through our app or third-party
            app integrations on your phone.
          </li>
        </ul>

        <Typography variant="h6" gutterBottom>
          2. How We Use Your Data
        </Typography>
        <Typography variant="body1" paragraph>
          We use this data to:
        </Typography>
        <ul>
          <li>Facilitate the core functionalities of the Reshape app.</li>
          <li>Customize and enhance your user experience.</li>
          <li>
            Provide insights, tips, and recommendations based on your data.
          </li>
          <li>
            Generate anonymized analytics to improve our services and
            functionality.
          </li>
        </ul>
        <Typography variant="body1" paragraph>
          Limited Use Disclosure:
        </Typography>
        <ul>
          <li>
            The use of information received from Health Connect will adhere to
            the requirements. Health Connect Permissions policy (see policy{" "}
            <a
              target="_blank_"
              href="https://support.google.com/googleplay/android-developer/answer/9888170?sjid=8998901795904597274-NA#ahp"
            >
              here
            </a>
            ) , including the Limited Use requirements.
          </li>
        </ul>
        <Typography variant="h6" gutterBottom>
          3. Storage of Personal Information
        </Typography>
        <Typography variant="body1" paragraph>
          We store the following personal information in our systems for the
          purpose of enabling personalization within the app: Name, Age, Sex,
          Height, Weight, and Email. This information is securely maintained to
          enhance the user experience.
        </Typography>

        {/* Section 4 */}
        <Typography variant="h6" gutterBottom>
          4. Storage of Health and Fitness Data
        </Typography>
        <Typography variant="body1" paragraph>
          In our app, we store various health and fitness-related data,
          including but not limited to:
        </Typography>
        <ul>
          <li>
            Nutrition data, which may include images of users' food, and
            nutrition analysis of their food.
          </li>
          <li>Workout progress information, including workout videos.</li>
          <li>
            Activity data, encompassing various activities such as yoga,
            running, and more.
          </li>
        </ul>
        <Typography variant="body1" paragraph>
          This data is stored securely within the app to provide users with a
          comprehensive fitness experience.
        </Typography>

        {/* Section 5 */}
        <Typography variant="h6" gutterBottom>
          5. Importing Health Data from Apple and Android Health Apps
        </Typography>
        <Typography variant="body1" paragraph>
          Upon user permission, we import steps and sleep data from their Apple
          and Android health apps. This integration is designed to enhance the
          accuracy and completeness of the user's health profile within the app.
          Users have the flexibility to import additional data, subject to their
          consent, as we expand our data import capabilities.
        </Typography>

        {/* Section 6 */}
        <Typography variant="h6" gutterBottom>
          6. Sharing and Disclosure
        </Typography>
        <Typography variant="body1" paragraph>
          We may share anonymized data with third-party analytics providers. No
          personally identifiable information will be shared with these entities
          unless explicitly authorized by you.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Data Retention and Removal
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to request the removal of your account and data
          from our servers. Upon receiving a deletion request, we will remove
          all identifiable data associated with your account. However, we
          reserve the right to retain anonymized data for analytics and
          improvement purposes.
        </Typography>

        {/* Section 8 */}
        <Typography variant="h6" gutterBottom>
          8. In-App AI (Fio) - Personalized Responses
        </Typography>
        <Typography variant="body1" paragraph>
          We have an in-app AI (Fio) that uses user information for personalized
          responses. Users can choose to share data with this AI-agent through
          Chat (text) and images. It is essential to note that Reshape does not
          accept liability for any incorrect responses generated by the AI. Fio
          is not a healthcare provider or a replacement for one. It is a
          convenient tool to assist users in their journey, and users should
          exercise caution, using their own judgment when acting on the advice
          provided.
        </Typography>

        {/* Section 9 */}
        <Typography variant="h6" gutterBottom>
          9. Data Protection
        </Typography>
        <Typography variant="body1" paragraph>
          We implement a variety of security measures to maintain the safety of
          your personal information. While no online service is 100% secure, we
          work hard to protect your data against unauthorized access, use,
          alteration, or destruction and take reasonable measures to do so.
        </Typography>

        {/* Section 10 */}
        <Typography variant="h6" gutterBottom>
          10. Liability Waiver for Risk of Injury
        </Typography>
        <Typography variant="body1" paragraph>
          While Reshape aims to assist users in their fitness journey, it's
          important to note that we are not healthcare practitioners. The
          content and recommendations in our app are for informational purposes
          only and should not replace professional medical advice. By using
          Reshape, you assume all risk for any injuries or adverse effects that
          may occur. We disclaim any liability for injuries or other adverse
          effects that may result from the use of our app.
        </Typography>
        <Typography variant="h6" gutterBottom>
          11. Cloud Storage and Data Access
        </Typography>
        <Typography variant="body1" paragraph>
          A copy of all user data is stored on our cloud servers, and stringent
          measures are in place to safeguard this information. Authorized
          administrators may access this data for monitoring, support, and
          product improvements.
        </Typography>

        {/* Section 13 */}
        <Typography variant="h6" gutterBottom>
          12. AI Model Training and Data Anonymization
        </Typography>
        <Typography variant="body1" paragraph>
          We do not use Personally Identifiable Information (PII) to train the
          AI model. Any data utilized for model improvements will undergo
          anonymization processes to ensure user privacy.
        </Typography>

        {/* Section 14 */}
        <Typography variant="h6" gutterBottom>
          13. Nutrition and Workout Advice Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          While we provide nutrition and workout advice, users acknowledge that
          such recommendations are suggestions and not substitutes for
          professional healthcare providers. We disclaim any liability for any
          harm, including injury, sickness, or death, arising from following the
          provided advice.
        </Typography>

        {/* Section 15 */}
        <Typography variant="h6" gutterBottom>
          14. Deletion of Account and Data Retention
        </Typography>
        <Typography variant="body1" paragraph>
          Users have the right to request the deletion of their account directly
          from the app. This action removes all identifiable information from
          our servers. However, we may retain anonymized data for analytics and
          improvement purposes.
        </Typography>
        <Typography variant="h6" gutterBottom>
          15. Storage of User Media
        </Typography>
        <Typography variant="body1" paragraph>
          We store user profile pictures and fitness progress photos on our
          servers. Users are encouraged to use this feature responsibly,
          considering the nature of the data being uploaded.
        </Typography>

        {/* Section 17 */}
        <Typography variant="h6" gutterBottom>
          16. User-Uploaded Data Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          We are not liable for any data uploaded by users onto our systems.
          Users are responsible for the content they upload, and they should
          ensure compliance with our terms of use and community guidelines.
        </Typography>

        {/* Section 18 */}
        <Typography variant="h6" gutterBottom>
          17. Note on Medical Advice
        </Typography>
        <Typography variant="body1" paragraph>
          Reshape is designed to guide you through your fitness journey, but we
          are not a substitute for professional healthcare advice. If you have
          medical conditions or need medical advice, it's crucial to consult
          with healthcare practitioners for proper diagnosis and treatment.
        </Typography>

        {/* Section 19 */}
        <Typography variant="h6" gutterBottom>
          18. Changes to this Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may occasionally update our privacy policy. Any changes made will
          be reflected on this page, so we encourage users to periodically
          review our privacy policy to stay informed.
        </Typography>

        {/* Section 20 */}
        <Typography variant="h6" gutterBottom>
          19. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns regarding this privacy policy,
          please contact us at info@nomatictech.com.
        </Typography>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
