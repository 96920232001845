/** @format */

import { Container, Typography, Divider } from "@mui/material";
import TopBar from "./components/Navbar";

const DeletionProcess = ({ navigation }: any) => {
  return (
    <>
      <TopBar navigation={navigation} />
      <Container sx={{ marginBlock: 10 }}>
        <Typography variant="h4" gutterBottom>
          Reshape User Account Deletion
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last Updated: 14 August 2023
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          1. Open Profile Page
        </Typography>
        <Typography variant="body1" paragraph>
          After logging in, click on the profile icon in the top right corner of
          the screen. This will open the profile page.
        </Typography>
        <br />
        <Typography variant="h6" gutterBottom>
          2. Click on Delete Account
        </Typography>
        <Typography variant="body1" paragraph>
          Click on the red "Delete Account" button. A confirmation dialog will
          appear. Click on the "Yes" button to confirm the deletion.The account
          will be deleted and the user will be logged out.
        </Typography>
      </Container>
    </>
  );
};

export default DeletionProcess;
