/** @format */

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialCard from "../components/TestimonialCard";
import { Box, Typography } from "@mui/material";
import { useRef, useState } from "react";

export default function Testimonials() {
  const [activeSlide, setActiveSlide] = useState(0);
  // Update the useRef with a type
  const sliderRef = useRef<Slider | null>(null);
  const testimonials = [
    {
      name: "Shubham Saini",
      image: "21",
      text: "Reshape is everything I need to track my goals. The app is flexible enough to honor any type of goals. What I like most about reshape is that it is AI powered. It detects my meal when I click a photo. No more typing and dictating my meals and intake.",
    },
    {
      name: "Mohammed Basheer",
      image: "6",
      text: "I've been using the Reshape app for the past three months, and I can't believe the results! I've managed to lose an incredible 19 kg, all thanks to its easy-to-use interface and accurate calorie counting. The app makes tracking my meals and exercise a breeze, and the daily reminders help keep me on track. The nutritional insights and personalized recommendations are spot-on, making my weight loss journey smoother and more effective.",
    },
    {
      name: "Pritish Kalra",
      image: "6",
      text: "Makes nutrition tracking simple, quick and easy. The AI agent is helpful, resourceful and seems to truly be along for the ride and be a reliable guide across my fitness journey.",
    },

    {
      name: "Aditya gupta",
      image: "6",
      text: "This app is just killing it...With this app I have been able to keep a good track on my nutrition intake and can se my body getting transformed....if you guys haven't used it please get this downloaded and then see the results happening...!!💯✅💪",
    },
  ];
  const handleDotClick = (index: any) => {
    setActiveSlide(index);
    if (sliderRef.current) {
      sliderRef?.current?.slickGoTo(index);
    }
  };
  return (
    <Box
      sx={{
        marginTop: { xs: 6, md: 10 },
        boxSizing: "border-box",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: 32, sm: 42 }, // Adjusts font size for xs screens, with default for sm and above
            textAlign: "center",
            fontFamily: "MyOwnFont,PublicSans",
            color: "#1D2538 ",
            marginBottom: 5,
            width: { xs: "60%", md: "100%" },
          }}
        >
          User testimonials
        </Typography>
      </Box>
      {/* Slider container with some padding */}
      <Slider
        ref={sliderRef}
        {...{
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          focusOnSelect: true,
          centerPadding: "300px", // Half of 532px, adjust based on actual visibility needs
          responsive: [
            {
              breakpoint: 1124,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                centerMode: true, // Optional for smaller screens
                centerPadding: "120px",
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                centerMode: true, // Optional for smaller screens
                centerPadding: "0px",
              },
            },

            // You can add more breakpoints here if needed
          ],
        }}
      >
        {testimonials.map((testimonial, index) => (
          <div key={index}>
            <TestimonialCard testimonial={testimonial} />
          </div>
        ))}
      </Slider>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
        {testimonials.map((_, index) => (
          <button
            key={index}
            style={{
              width: activeSlide === index ? 16 : 8,
              height: 8,
              borderRadius: activeSlide === index ? "30%" : "0%",
              background: activeSlide === index ? "#0066ff" : " #C4C4C4 ",
              border: "none",
              margin: 2,
              cursor: "pointer",
            }}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </Box>
    </Box>
  );
}
